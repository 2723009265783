.tooltip-market-chart {
  padding: 3px;
  font-size: 13px;
  color: #000;
}

.tooltip-tctt-chart {
  padding: 3px;
  font-size: 13px;
  color: #000;
  display: flex;
  flex-direction: column;
}

.new-html-content a {
  display: none;
}

.new-html-content img {
  max-width: 100% !important;
  max-height: 100% !important;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover>.resizer {
    opacity: 1;
  }
}

.rc-calendar-picker {
  z-index: 9999 !important;
}

#wrapper {
  padding-top: 20px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgb(0 0 0 / 10%);
  width: 300px;
  margin: 35px auto;
}

.recharts-label {
  font-size: 12px;
}

.tooltip-vile {
  border-radius: 4px;
  padding: 0px 10px;
  background: #373d4f;
  color: #fff;
  margin: 0px 20px;
  box-shadow: 5px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  height: 100%;
  pointer-events: none;
  user-select: none;
}

.tooltip {
  border-radius: 4px;
  background: #373d4f;
  color: #fff;
  margin: 0px 20px;
  box-shadow: 5px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: left;
  height: 100%;
  pointer-events: none;
  user-select: none;
}

.tooltip .ttheader {
  border-radius: 4px 4px 0 0;
  height: 20px;
  background-color: #42495c;
  font-size: 11px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  padding-top: 2px;
  padding-left: 5px;
  margin-bottom: 4px;
}

.tooltip .ttbody {
  text-align: left;
  font-size: 11px;
  margin: 0;
  padding: 5px 5px 5px 5px;
}

.customized-legend {
  align-items: center;
  justify-content: center;
  display: flex;
}

.recharts-brush-texts {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(134, 136, 141, 0.8);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(134, 136, 141, 0.8);
}

.brush-pttb-chart {
  top: -20px;
  z-index: 0;
  pointer-events: none;
}

.two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .recharts-brush-traveller {
 cursor: pointer !important;
} */

.recharts-cartesian-axis-ticks * {
  fill: rgba(255, 255, 255, 0.8);
}

.css-xb1v0w-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 13px !important;
}

.recharts-brush-slide {
  fill: blue !important;
}

.recharts-brush-traveller {
  cursor: pointer !important;
}

.recharts-brush-texts>text {
  fill: white;
}

#root {
  min-width: auto;
  text-size-adjust: auto;
}

@media only screen and (max-width: 678px) {
  #root {
    min-width: 1024px;
  }
}

.quadrant-title {
  display: inline-block;
  opacity: 0.6;
  color: #000;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
}

.css-7epnn8-MuiFormLabel-asterisk {
  color: #e3b309;
}

.WLTab.Mui-selected {
  background: #f2bb0e;
  color: #000 !important;
}

.css-s8om6f-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 0.1px solid #f2bb0e !important;
}

.css-1fp4l0u-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #f2bb0e !important;
  background-color: #f2bb0e00 !important;
}

/* Tooltip text */
.tooltiptext {
  width: 250px;
  background-color: #41495c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
}

/* .react-financial-charts-tooltip {
  fill: white !important;
}

.react-financial-charts-tooltip-hover {
  fill: white !important;
} */

.CGChart .recharts-layer.recharts-brush {
  visibility: hidden;
}

.cgpanel ::-webkit-scrollbar {
  display: none !important;
}

.cgpanel .ReactVirtualized__Table__headerRow {
  position: sticky;
  inset-block-start: 0;
}

.cgpanel .css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 3px 3px 3px 6px !important;
  font-size: 0.8rem !important;
}

.MuiTableCell-head,
.MuiTableCell-root {
  padding: 3px !important;
  height: 35px !important;
}

.stock-selection {
  z-index: 1;
}

.home-icon .MuiPaper-root {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.css-rhaicg-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper {
  margin-left: 2px !important;
}

.react-select__indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  padding-top: 2px;
  width: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabpage-taichinh::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabpage-taichinh {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.h-100 {
  overflow: hidden;
}

/* ::-webkit-scrollbar-track {
  background-color: transparent;
} */

/* ::-webkit-scrollbar {
  right: 0;
  width: 12px;
  height: 12px;
} */
/* 
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 12px;
  height: 12px;
} */

/* .tdse .MuiToggleButtonGroup-lastButton,
.css-1iedkww-MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton .MuiToggleButtonGroup-firstButton,
.css-1iedkww-MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton {
  margin-left: -1px !important;
  border-left: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.sswd-react-select-container .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
} */

.vertical-timeline-element-content {
  position: relative !important;
  margin-left: 10px !important;
  background: transparent !important;
  /* border-radius: .25em; */
  padding: 1em !important;
  -webkit-box-shadow: none !important;
  /* box-shadow: 0 3px 0 #ddd; */
  width: 47% !important;
}

.vertical-timeline-element-content p {
  margin: 0 !important;
  line-height: 1.6 !important;
}

.vertical-timeline-element-date {
  opacity: 1 !important;
}

span.vertical-timeline-element-date {
  font-weight: bold !important;
}

div.vertical-timeline-element-date {
  font-weight: unset !important;
  font-style: italic;
} 

.vertical-timeline-element {
  margin: 0 !important;
}

.vertical-timeline {
  padding: 1em 0 !important;
}

.MuiTabs-flexContainer {
  flex-wrap: nowrap !important;
  overflow: auto;
  padding-bottom: 8px;
  scrollbar-width: none;
}

.MuiTabs-flexContainer::-webkit-scrollbar {
  display: none;
}